export const image_uploader = {
    height: 175,
    backgroundColor: '#dcdcdc',
    border: 'solid black 1px',
    marginTop: 50
};

export const upload_image = {
    height: 100,
    width: 100
}
