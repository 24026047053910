import React from 'react';
import { useState, setIndex } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



export default class FAQPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container>
                <br />
                <br />
                <br />
                <br />
                <Row>
                    <Col>
                        <h1 style={{float: "left", color: "white"}}>Frequently Asked Questions(FAQs)</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{float: "left", color: "#5a96de"}}>WHAT KIND OF IMAGES CAN I UPLOAD?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                            <p style={{color: "white", float: "left"}}>As of right now we only support.png and jpg files.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{float: "left", color: "#5a96de"}}>CAN I UPLOAD AN IMAGE OF XYZ?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{color: "white", float: "left"}}>We will not make a patch that violates any laws and we reserve the right to refuse any designs.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{float: "left", color: "#5a96de"}}>WHY DOESN'T MY IMAGE LOOK GOOD ON THE PREVIEW SCREEN?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{color: "white", float: "left"}}>Unfortunately not all images make good patches. Images with a lot of small detail tend to not look great and are not possible to embroider.</p>
                        <p style={{color: "white", float: "left"}}>We recommend simplifying your image using editing software such as Gimp and reuploading the image to see the results.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{float: "left", color: "#5a96de"}}>WHY ARE SOME OF THE DETAILS OF MY IMAGE MISSING?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{color: "white", float: "left"}}>Due to the constraints of embroidery, not every detail can be embroidered.</p>
                        <p style={{color: "white", float: "left"}}>There is a limit in what embroidery can accomplish and we try to visualize it as much as we can.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{float: "left", color: "#5a96de"}}>WHY DOES SOME OF THE TEXT NOT RENDER CORRECTLY?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{color: "white", float: "left"}}>Unfortunately this comes down to detail.
                        Some fonts are too precise for embroidery to be able to do.
                        We recommend using blocky and well defined fonts.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{float: "left", color: "#5a96de"}}>HOW CAN I MAKE MY IMAGE RENDER BETTER?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{color: "white", float: "left"}}>Excellent question!
                        The single best thing one can do to make their images and patches look better is to center on the design.</p>
                        <p style={{color: "white", float: "left"}}>Below is an example of cropping the design out of the image to yield better results.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{float: "left", color: "#5a96de"}}>WHAT IF I WOULD LIKE TO ORDER IN A BULK?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{color: "white", float: "left"}}>The answer can depend on each situation, please email us as hybridtextilesllc@gmail.com for more information.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}
