import React from 'react';
import { useState } from 'react';
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

import { STATE_LIST, TAX_RATE, COUNTRY_LIST } from '../../constants.js';
import { ErrorModal, TOSModal } from '../../CommonComponents/common_components.js';
import { container, receiptRowLight, receiptRowDark, receiptContainer, finalTotalContainer, errorShadow, visaMCDiscoverLength, amexLength, noShadow, visaMCDiscoverCVVLength, amexCVVLength, ccStyle } from './index.css.js';
import { allText, allNumbers, isBlank, isRFC822ValidEmail, isNum } from '../../CommonComponents/utilities.js';

import {cc_brand_id} from '../../CommonComponents/math_functions.js';

import VisaIcon from '../../Images/visa-icon.png';
import MastercardIcon from '../../Images/mastercard-icon.png';
import DiscoverIcon from '../../Images/discover-icon.png';
import AmexIcon from '../../Images/amex-icon.png';
import VisaIconFrozen from '../../Images/visa-icon-frozen.png';
import MastercardIconFrozen from '../../Images/mastercard-icon-frozen.png';
import DiscoverIconFrozen from '../../Images/discover-icon-frozen.png';
import AmexIconFrozen from '../../Images/amex-icon-frozen.png';

import { PayPalButton } from "react-paypal-button-v2";

export default class CheckoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email_address: '',
            card_number: '',
            card_expiration_month: '',
            card_expiration_year: '',
            card_cvv: '',
            billing_address_1: '',
            billing_address_2: '',
            billing_state: STATE_LIST[0],
            billing_city: '',
            billing_zip: '',
            billing_country: 'United States',
            shipping_address_1: '',
            shipping_address_2: '',
            shipping_state: STATE_LIST[0],
            shipping_city: '',
            shipping_zip: '',
            shipping_country: 'United States',
            shipping_is_billing: true,
            subtotal: 0.00,
            shipping: 0.00,
            tax: 0.00,
            total: 0.00,
            order_tech: this.props.order,
            modal_show: false,
            modal_body: '',
            modal_header: 'Field Error',
            tos_modal_show: false,
            shipping_rate_id: '',
            shipping_has_been_calculated: false,
            waiting_for_shipping_calculation: false,
            submitted: false,
            tos_accepted: true,
            card_type: '',
            card_number_style: errorShadow,
            card_cvv_style: errorShadow,
            card_expiration_year_style: errorShadow,
            card_expiration_month_style: errorShadow,
            payment_method: 'Paypal',
            notes: '',
            coupon_code: '',
            coupon_discount: 1,
            dropship: false,
            dropship_address_1: '',
            dropship_address_2: '',
            dropship_state: '',
            dropship_city: '',
            dropship_zipcode: '',
            dropship_country: 'United States',
            dropship_business_name: '',
            dropship_recepient_first_name: '',
            dropship_recepient_last_name: '',
            dropship_recepient_address_1: '',
            dropship_recepient_address_2: '',
            dropship_recepient_country: 'United States',
            dropship_recepient_zipcode: '',
            dropship_recepient_state: '',
            shipping_method: 'PriorityMail'
        };
    }

    componentDidMount() {
        var subtotal = 0.00;
        for(let i = 0; i < this.props.order.length; i++) {
            subtotal += this.props.order[i].price;
        }
        var coupon_subtotal = subtotal * this.state.coupon_discount;
        this.setState(
            {
                subtotal: subtotal.toFixed(2),
                coupon_subtotal: coupon_subtotal.toFixed(2),
                tax: ((coupon_subtotal + this.state.shipping)*TAX_RATE).toFixed(2),
                total: (coupon_subtotal+(coupon_subtotal + this.state.shipping)*TAX_RATE).toFixed(2)
            }
        );
    }

    toggleTOS() {
        this.setState(
            {
                tos_accepted: !this.state.tos_accepted
            }
        );
    }

    onFirstNameChange(e) {
        this.setState(
            {
                first_name: e.target.value,
                shipping_has_been_calculated: false  // prevent checkout from paypal if name changes after shipping is calculated
            }
        );
    }

    onLastNameChange(e) {
        this.setState(
            {
                last_name: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    onEmailAddressChange(e) {
        this.setState(
            {
                email_address: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    onCardNumberChange(e) {
        var card_type = cc_brand_id(e.target.value);
        var card_number_style;
        if(isNum(e.target.value)) {
            if(card_type == 'amex') {
                if(e.target.value.length != amexLength) {
                    card_number_style = errorShadow;
                } else {
                    card_number_style = noShadow;
                }
            } else if(card_type == 'visa' || card_type == 'mastercard' || card_type == 'discover') {
                if(e.target.value.length != visaMCDiscoverLength) {
                    card_number_style = errorShadow;
                } else {
                    card_number_style = noShadow;
                }
            } else {
                card_number_style = errorShadow;
            }
        } else {
            card_number_style = errorShadow;
        }
        this.setState(
            {
                card_number: e.target.value,
                card_type: card_type,
                card_number_style: card_number_style,
                shipping_has_been_calculated: false
            }
        );
    }

    onCardExpirationMonthChange(e) {
        var card_expiration_month_style;
        if(isNum(e.target.value) && (e.target.value.length == 2 || e.target.value.length == 1)) {
            card_expiration_month_style = noShadow;
        } else {
            card_expiration_month_style = errorShadow;
        }
        this.setState(
            {
                card_expiration_month: e.target.value,
                card_expiration_month_style: card_expiration_month_style,
                shipping_has_been_calculated: false
            }
        );
    }

    onCardExpirationYearChange(e) {
        var card_expiration_year_style;
        if(isNum(e.target.value) && e.target.value.length == 2) {
            card_expiration_year_style = noShadow;
        } else {
            card_expiration_year_style = errorShadow;
        }
        this.setState(
            {
                card_expiration_year: e.target.value,
                card_expiration_year_style: card_expiration_year_style,
                shipping_has_been_calculated: false
            }
        );
    }

    onCardCVVChange(e) {
        var cvv_style;
        if(isNum(e.target.value)) {
            if(this.state.card_type === 'amex') {
                if(e.target.value.length !== 4) {
                    cvv_style = errorShadow;
                } else {
                    cvv_style = noShadow;
                }
            } else if(this.state.card_type === 'unknown') {
                cvv_style = errorShadow;
            } else {
                if(e.target.value.length !== 3) {
                    cvv_style = errorShadow;
                } else {
                    cvv_style = noShadow;
                }
            }
        } else {
            cvv_style = errorShadow;
        }
        this.setState(
            {
                card_cvv: e.target.value,
                card_cvv_style: cvv_style,
                shipping_has_been_calculated: false
            }
        );
    }

    onBillingAddress1Change(e) {
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    billing_address_1: e.target.value,
                    shipping_address_1: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        } else {
            this.setState(
                {
                    billing_address_1: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        }
    }

    onBillingAddress2Change(e) {
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    billing_address_2: e.target.value,
                    shipping_address_2: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        } else {
            this.setState(
                {
                    billing_address_2: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        }
    }

    onBillingStateChange(e) {
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    billing_state: e.target.value,
                    shipping_state: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        } else {
            this.setState(
                {
                    billing_state: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        }
    }

    onBillingCountryChange(e) {
        var shipping_method = '';
        if(e.target.value === 'United States') {
            shipping_method = 'PriorityMail';
        } else {
            shipping_method = 'InternationalPackage';
        }
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    billing_country: e.target.value,
                    shipping_country: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                    shipping_method: shipping_method
                }
            );
        } else {
            this.setState(
                {
                    billing_country: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                    shipping_method: shipping_method
                }
            );
        }
    }

    onBillingCityChange(e) {
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    billing_city: e.target.value,
                    shipping_city: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        } else {
            this.setState(
                {
                    billing_city: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        }
    }

    onBillingZipChange(e) {
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    billing_zip: e.target.value,
                    shipping_zip: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        } else {
            this.setState(
                {
                    billing_zip: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                }
            );
        }
    }

    onShippingAddress1Change(e) {
        this.setState(
            {
                shipping_address_1: e.target.value,
                shipping_has_been_calculated: false,
                shipping: 0.00,
            }
        );
    }

    onShippingAddress2Change(e) {
        this.setState(
            {
                shipping_address_2: e.target.value,
                shipping_has_been_calculated: false,
                shipping: 0.00,
            }
        );
    }

    onShippingStateChange(e) {
        this.setState(
            {
                shipping_state: e.target.value,
                shipping_has_been_calculated: false,
                shipping: 0.00,
            }
        );
    }

    onShippingCityChange(e) {
        this.setState(
            {
                shipping_city: e.target.value,
                shipping_has_been_calculated: false,
                shipping: 0.00,
            }
        );
    }

    onShippingZipChange(e) {
        this.setState(
            {
                shipping_zip: e.target.value,
                shipping_has_been_calculated: false,
                shipping: 0.00,
            }
        );
    }

    onShippingCountryChange(e) {
        var shipping_method = '';
        if(e.target.value === 'United States') {
            shipping_method = 'PriorityMail';
        } else {
            shipping_method = 'InternationalPackage';
        }
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    billing_country: e.target.value,
                    shipping_country: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                    shipping_method: shipping_method
                }
            );
        } else {
            this.setState(
                {
                    shipping_country: e.target.value,
                    shipping_has_been_calculated: false,
                    shipping: 0.00,
                    shipping_method: shipping_method
                }
            );
        }
    }

    onDropshipRecepientFirstNameChange(e) {
        this.setState(
            {
                dropship_recepient_first_name: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipRecepientLastNameChange(e) {
        this.setState(
            {
                dropship_recepient_last_name: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipBusinessNameChange(e) {
        this.setState(
            {
                dropship_business_name: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipAddress1Change(e) {
        this.setState(
            {
                dropship_address_1: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipAddress2Change(e) {
        this.setState(
            {
                dropship_address_2: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipStateChange(e) {
        this.setState(
            {
                dropship_state: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipCityChange(e) {
        this.setState(
            {
                dropship_city: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipZipChange(e) {
        this.setState(
            {
                dropship_zipcode: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipCountryChange(e) {
        this.setState(
            {
                dropship_country: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipRecepientAddress1Change(e) {
        this.setState(
            {
                dropship_recepient_address_1: e.target.value,
                shipping_has_been_calculated: false,
            }
        );
    }

    onDropshipRecepientAddress2Change(e) {
        this.setState(
            {
                dropship_recepient_address_2: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    onDropshipRecepientStateChange(e) {
        this.setState(
            {
                dropship_recepient_state: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    onDropshipRecepientCityChange(e) {
        this.setState(
            {
                dropship_recepient_city: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    onDropshipRecepientZipCodeChange(e) {
        this.setState(
            {
                dropship_recepient_zipcode: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    onDropshipRecepientCountryChange(e) {
        this.setState(
            {
                dropship_recepient_country: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    onShippingIsBillingToggle(e) {
        if(this.state.shipping_is_billing) {
            this.setState(
                {
                    shipping_is_billing: !this.state.shipping_is_billing,
                }
            );
        } else {
            this.setState(
                {
                    shipping_is_billing: !this.state.shipping_is_billing,
                    shipping_address_1: this.state.billing_address_1,
                    shipping_address_2: this.state.billing_address_2,
                    shipping_city: this.state.billing_city,
                    shipping_state: this.state.billing_state,
                    shipping_zip: this.state.billing_zip
                }
            );
        }
    }

    onDropshipToggle(e) {
        this.setState(
            {
                dropship: !this.state.dropship,
                shipping_has_been_calculated: false,
            }
        );
    }

    renderStateSelectList() {
        var state_list = [];
        for(let i = 0; i < STATE_LIST.length; i++) {
            state_list.push(
                <option>{STATE_LIST[i]}</option>
            );
        }
        return state_list;
    }

    renderCountrySelectList() {
        var country_list = [];
        for(let i = 0; i < COUNTRY_LIST.length; i++) {
            country_list.push(
                <option>{COUNTRY_LIST[i]}</option>
            );
        }
        return country_list;
    }

    renderPersonalInfo() {
        return (
            <Container style={{border: 'solid #5f88b7 2px'}}>
                <Form.Row>
                    <Col>
                        <b>
                            Personal Information
                        </b>
                    </Col>
                </Form.Row>
                <Form.Row >
                    <Form.Group as={Col} sm='4'>
                        <Form.Control type='text' placeholder={'First Name'} onChange={(e) => {this.onFirstNameChange(e);}} value={this.state.first_name} />
                    </Form.Group>
                    <Form.Group as={Col} sm='4'>
                        <Form.Control type='text' placeholder={'Last Name'} onChange={(e) => {this.onLastNameChange(e);}} value={this.state.last_name} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} sm='4'>
                        <Form.Control type='email' placeholder={'Email@Address.com'} onChange={(e) => {this.onEmailAddressChange(e);}} value={this.state.email_address} />
                    </Form.Group>
                </Form.Row>
            </Container>
        );
    }

    renderCreditCardType() {
        switch(this.state.card_type) {
            case '':
                return [
                    <Image src={VisaIcon} style={ccStyle}/>,
                    <Image src={MastercardIcon} style={ccStyle}/>,
                    <Image src={AmexIcon} style={ccStyle}/>,
                    <Image src={DiscoverIcon} style={ccStyle}/>,
                ];
            case 'visa':
                return [
                    <Image src={VisaIcon} style={ccStyle}/>,
                    <Image src={MastercardIconFrozen} style={ccStyle}/>,
                    <Image src={AmexIconFrozen} style={ccStyle}/>,
                    <Image src={DiscoverIconFrozen} style={ccStyle}/>,
                ];
            case 'mastercard':
                return [
                    <Image src={VisaIconFrozen} style={ccStyle}/>,
                    <Image src={MastercardIcon} style={ccStyle}/>,
                    <Image src={AmexIconFrozen} style={ccStyle}/>,
                    <Image src={DiscoverIconFrozen} style={ccStyle}/>,
                ];
            case 'amex':
                return [
                    <Image src={VisaIconFrozen} style={ccStyle}/>,
                    <Image src={MastercardIconFrozen} style={ccStyle}/>,
                    <Image src={AmexIcon} style={ccStyle}/>,
                    <Image src={DiscoverIconFrozen} style={ccStyle}/>,
                ];
            case 'discover':
                return [
                    <Image src={VisaIconFrozen} style={ccStyle}/>,
                    <Image src={MastercardIconFrozen} style={ccStyle}/>,
                    <Image src={AmexIconFrozen} style={ccStyle}/>,
                    <Image src={DiscoverIcon} style={ccStyle}/>,
                ];
            default:
                return [
                    <Image src={VisaIcon} style={ccStyle}/>,
                    <Image src={MastercardIcon} style={ccStyle}/>,
                    <Image src={AmexIcon} style={ccStyle}/>,
                    <Image src={DiscoverIcon} style={ccStyle}/>,
                ];
        }
    }

    paymentMethodChange(e) {
        this.setState(
            {
                payment_method: e.target.value
            }
        );
    }

    renderCreditCardInformation() {
        if(this.state.payment_method === 'Credit Card') {
            return (
                <Container style={{border: 'solid #5f88b7 2px'}}>
                    <Form.Row><Col><b>Payment Information</b></Col></Form.Row>
                    <Form.Row>
                        <Form.Control as='select' onChange={(e) => {this.paymentMethodChange(e);}} value={this.state.payment_method}>
                                <option>Credit Card</option>
                                <option>Paypal</option>
                        </Form.Control>
                    </Form.Row>
                    <Form.Row className='justify-content-begin' style={{marginTop: 10}}>
                        <Col sm='4' style={{marginLeft: 20}}>
                            <Row>
                                <Form.Control type='text' placeholder={'CC Number'} style={this.state.card_number_style} onChange={(e) => {this.onCardNumberChange(e);}} value={this.state.card_number} />
                            </Row>
                            <Row style={{marginTop: 15}} >
                                {this.renderCreditCardType()}
                            </Row>
                        </Col>
                        <Col sm='1' style={{marginLeft: 20}}>
                            <Form.Control type='text' placeholder={'CVV'} style={this.state.card_cvv_style} onChange={(e) => {this.onCardCVVChange(e);}} value={this.state.card_cvv} />
                        </Col>
                    </Form.Row>
                    <Form.Row className='justify-content-begin' style={{marginTop: 15, marginLeft: 2}}>
                        <Col sm='1'>
                            <Form.Control type='text' placeholder={'MM'} style={this.state.card_expiration_month_style} onChange={(e) => {this.onCardExpirationMonthChange(e);}} value={this.state.card_expiration_month} />
                        </Col>
                        <Col sm='0'>
                            <Form.Label>/</Form.Label>
                        </Col>
                        <Col sm='1'>
                            <Form.Control type='text' placeholder={'YY'} style={this.state.card_expiration_year_style} onChange={(e) => {this.onCardExpirationYearChange(e);}} value={this.state.card_expiration_year} />
                        </Col>
                    </Form.Row>
                </Container>
            );
        } else {
            return(
                <Container style={{border: 'solid #5f88b7 2px'}}>
                    <Form.Row><Col><b>Payment Information</b></Col></Form.Row>
                    <Form.Row>
                        <Form.Control as='select' onChange={(e) => {this.paymentMethodChange(e);}} value={this.state.payment_method}>
                                        <option>Credit Card</option>
                                        <option>Paypal</option>
                        </Form.Control>
                    </Form.Row>
                </Container>
            );
        }
    }

    renderBillingAddressInfo() {
        if(!this.state.dropship) {
            if(this.state.billing_country === 'United States') {
                return (
                    <Container style={{border: 'solid #5f88b7 2px'}}>
                        <b>Billing Address</b> <br />
                        Billing Address is Same as Shipping address? <Form.Check inline type='checkbox' onChange={(e) => {this.onShippingIsBillingToggle(e);}} checked={this.state.shipping_is_billing} />
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onBillingAddress1Change(e);}} value={this.state.billing_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onBillingAddress2Change(e);}} value={this.state.billing_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onBillingCityChange(e);}} value={this.state.billing_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control as='select' onChange={(e) => {this.onBillingStateChange(e);}} value={this.state.billing_state}>
                                    {this.renderStateSelectList()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onBillingZipChange(e);}} value={this.state.billing_zip} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onBillingCountryChange(e);}} value={this.state.billing_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </Container>
                );
            } else {
                return (
                    <Container style={{border: 'solid #5f88b7 2px'}}>
                        <b>Billing Address</b> <br />
                        Billing Address is Same as Shipping address? <Form.Check inline type='checkbox' onChange={(e) => {this.onShippingIsBillingToggle(e);}} checked={this.state.shipping_is_billing} />
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onBillingAddress1Change(e);}} value={this.state.billing_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onBillingAddress2Change(e);}} value={this.state.billing_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onBillingCityChange(e);}} value={this.state.billing_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'State'} onChange={(e) => {this.onBillingStateChange(e);}} value={this.state.billing_state} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onBillingZipChange(e);}} value={this.state.billing_zip} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onBillingCountryChange(e);}} value={this.state.billing_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </Container>
                );
            }
        } else {
            if(this.state.billing_country === 'United States') {
                return(
                    <Container style={{border: 'solid #5f88b7 2px'}}>
                        <b>Billing Address</b> <br />
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onBillingAddress1Change(e);}} value={this.state.billing_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onBillingAddress2Change(e);}} value={this.state.billing_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onBillingCityChange(e);}} value={this.state.billing_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control as='select' onChange={(e) => {this.onBillingStateChange(e);}} value={this.state.billing_state}>
                                        {this.renderStateSelectList()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onBillingZipChange(e);}} value={this.state.billing_zip} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onBillingCountryChange(e);}} value={this.state.billing_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </Container>
                );
            } else {
                return(
                    <Container style={{border: 'solid #5f88b7 2px'}}>
                        <b>Billing Address</b> <br />
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onBillingAddress1Change(e);}} value={this.state.billing_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onBillingAddress2Change(e);}} value={this.state.billing_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onBillingCityChange(e);}} value={this.state.billing_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' onChange={(e) => {this.onBillingStateChange(e);}} value={this.state.billing_state}>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onBillingZipChange(e);}} value={this.state.billing_zip} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onBillingCountryChange(e);}} value={this.state.billing_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </Container>
                );
            }
        }
    }

    renderShippingAddressInfo() {
        if(!this.state.dropship) {
            if(this.state.shipping_country === 'United States') {
                return (
                    <Container style={{border: 'solid #5f88b7 2px'}}>
                        <b>Shipping Address</b>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onShippingAddress1Change(e);}} value={this.state.shipping_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onShippingAddress2Change(e);}} value={this.state.shipping_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onShippingCityChange(e);}} value={this.state.shipping_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control as='select' onChange={(e) => {this.onShippingStateChange(e);}} value={this.state.shipping_state}>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onShippingZipChange(e);}} value={this.state.shipping_zip} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onShippingCountryChange(e);}} value={this.state.shipping_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </Container>
                );
            } else {
                return (
                    <Container style={{border: 'solid #5f88b7 2px'}}>
                        <b>Shipping Address</b>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onShippingAddress1Change(e);}} value={this.state.shipping_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onShippingAddress2Change(e);}} value={this.state.shipping_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onShippingCityChange(e);}} value={this.state.shipping_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'State'} onChange={(e) => {this.onShippingStateChange(e);}} value={this.state.shipping_state} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onShippingZipChange(e);}} value={this.state.shipping_zip} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control type='select' onChange={(e) => {this.onShippingCountryChange(e);}} value={this.state.shipping_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </Container>
                );
            }
        } else {
            return(<></>);
        }
    }

    renderDropshipToggle() {
        return(
            <Form.Row style={{marginTop: 20}}>
                <b>Dropshipping?</b> <Form.Check inline type='checkbox' onChange={(e) => {this.onDropshipToggle(e);}} checked={this.state.dropship} />
            </Form.Row>
        );
    }
    renderDropshipAddressInfo() {
        var dropship_address_info = [];
        if(this.state.dropship) {
            if(this.state.dropship_country === 'United States') {
                dropship_address_info.push(
                    <>
                        <b>Dropship Business Address</b>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Business Name'} onChange={(e) => {this.onDropshipBusinessNameChange(e);}}
                            value={this.state.dropship_business_name} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onDropshipAddress1Change(e);}} value={this.state.dropship_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onDropshipAddress2Change(e);}} value={this.state.dropship_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onDropshipCityChange(e);}} value={this.state.dropship_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control as='select' onChange={(e) => {this.onDropshipStateChange(e);}} value={this.state.dropship_state}>
                                    {this.renderStateSelectList()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onDropshipZipChange(e);}} value={this.state.dropship_zipcode} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onDropshipCountryChange(e);}} value={this.state.dropship_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </>
                );
            } else {
                dropship_address_info.push(
                    <>
                        <b>Dropship Business Address</b>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Control type='text' placeholder={'Business Name'} onChange={(e) => {this.onDropshipBusinessNameChange(e);}}
                            value={this.state.dropship_business_name} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onDropshipAddress1Change(e);}} value={this.state.dropship_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onDropshipAddress2Change(e);}} value={this.state.dropship_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onDropshipCityChange(e);}} value={this.state.dropship_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'State'} onChange={(e) => {this.onDropshipStateChange(e);}} value={this.state.dropship_state} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onDropshipZipChange(e);}} value={this.state.dropship_zipcode} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onDropshipCountryChange(e);}} value={this.state.dropship_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </>
                );
            }
        }
        return dropship_address_info;
    }


    renderDropshipRecepientInfo() {
        if(this.state.dropship) {
            if(this.state.dropship_recepient_country === 'United States') {
                return(
                    <>
                        <b>Dropship Recepient Address</b>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col} sm='4'>
                                <Form.Control type='text' placeholder={'First Name'} onChange={(e) => {this.onDropshipRecepientFirstNameChange(e);}} value={this.state.dropship_recepient_first_name} />
                            </Form.Group>
                            <Form.Group as={Col} sm='4'>
                                <Form.Control type='text' placeholder={'Last Name'} onChange={(e) => {this.onDropshipRecepientLastNameChange(e);}} value={this.state.dropship_recepient_last_name} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onDropshipRecepientAddress1Change(e);}} value={this.state.dropship_recepient_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onDropshipRecepientAddress2Change(e);}} value={this.state.dropship_recepient_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onDropshipRecepientCityChange(e);}} value={this.state.dropship_recepient_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control as='select' onChange={(e) => {this.onDropshipRecepientStateChange(e);}} value={this.state.dropship_recepient_state}>
                                    {this.renderStateSelectList()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onDropshipRecepientZipCodeChange(e);}} value={this.state.dropship_recepient_zipcode} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onDropshipRecepientCountryChange(e);}} value={this.state.dropship_recepient_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </>
                );
            } else {
                return(
                    <>
                        <b>Dropship Address</b>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col} sm='4'>
                                <Form.Control type='text' placeholder={'First Name'} onChange={(e) => {this.onDropshipRecepientFirstNameChange(e);}} value={this.state.dropship_recepient_first_name} />
                            </Form.Group>
                            <Form.Group as={Col} sm='4'>
                                <Form.Control type='text' placeholder={'Last Name'} onChange={(e) => {this.onDropshipRecepientLastNameChange(e);}} value={this.state.dropship_recepient_last_name} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 1'} onChange={(e) => {this.onDropshipRecepientAddress1Change(e);}} value={this.state.dropship_recepient_address_1} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 5}}>
                            <Form.Control type='text' placeholder={'Address 2'} onChange={(e) => {this.onDropshipRecepientAddress2Change(e);}} value={this.state.dropship_recepient_address_2} />
                        </Form.Row>
                        <Form.Row style={{marginTop: 10}}>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'City'} onChange={(e) => {this.onDropshipRecepientCityChange(e);}} value={this.state.dropship_recepient_city} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'State'} onChange={(e) => {this.onDropshipRecepientStateChange(e);}} value={this.state.dropship_recepient_state} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Control type='text' placeholder={'Zip Code'} onChange={(e) => {this.onDropshipRecepientZipCodeChange(e);}} value={this.state.dropship_recepient_zipcode} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control as='select' onChange={(e) => {this.onDropshipRecepientCountryChange(e);}} value={this.state.dropship_recepient_country}>
                                    {this.renderCountrySelectList()}
                            </Form.Control>
                        </Form.Row>
                    </>
                );
            }
        } else {
            return(<></>);
        }
    }

    onCouponCodeChange(e) {
        this.setState(
            {
                coupon_code: e.target.value
            }
        );
    }

    onCalculateCoupon() {
        axios.post('https://api.hybridtextiles.org/api', {flag: 'coupon', 'coupon': this.state.coupon_code}
        ).then((response) => {
            if(response.data.success) {
                if(response.data.value !== false) {
                    var coupon_discount = 1 - response.data.value;
                    var coupon_subtotal = this.state.subtotal * coupon_discount;
                    var shipping = parseFloat(this.state.shipping);
                    var tax = (coupon_subtotal + shipping)*TAX_RATE;
                    var total = coupon_subtotal + shipping + tax;
                    this.setState(
                        {
                            coupon_discount: coupon_discount.toFixed(2),
                            coupon_subtotal: coupon_subtotal.toFixed(2),
                            tax: tax.toFixed(2),
                            total: total.toFixed(2)
                        }
                    );
                    alert('Successfully Applied Coupon');
                } else {
                    var coupon_discount = 1;
                    var coupon_subtotal = (this.state.subtotal * coupon_discount);
                    shipping = parseFloat(this.state.shipping);
                    var tax = (coupon_subtotal + shipping)*TAX_RATE;
                    var total = coupon_subtotal + shipping + tax;
                    this.setState(
                        {
                            coupon_discount: coupon_discount.toFixed(2),
                            coupon_subtotal: coupon_subtotal.toFixed(2),
                            tax: tax.toFixed(2),
                            total: total.toFixed(2)
                        }
                    );
                    alert('Invalid Coupon');
                }
            } else {
                var coupon_discount = 1;
                var coupon_subtotal = (this.state.subtotal * coupon_discount);
                shipping = parseFloat(this.state.shipping);
                var tax = (coupon_subtotal + shipping)*TAX_RATE;
                var total = coupon_subtotal + shipping + tax;
                this.setState(
                    {
                        coupon_discount: coupon_discount.toFixed(2),
                        coupon_subtotal: coupon_subtotal.toFixed(2),
                        tax: tax.toFixed(2),
                        total: total.toFixed(2)
                    }
                );
                alert('Error Processing Coupon, Please Contact Support');
            }
        });
    }

    renderCouponCode() {
        var coupon_variant = 'info';
        return (
            <>
                <Form.Row style={{marginTop: 10}}>
                    <Form.Group as={Col} lg={3}>
                        <Form.Control type='text' placeholder='Coupon Code' onChange={(e) => {this.onCouponCodeChange(e);}} value={this.state.coupon_code}/>
                    </Form.Group>
                    <Col lg={3}>
                        <Button size='lg' variant={coupon_variant} onClick={() => {this.onCalculateCoupon();}}><b>Apply Coupon</b></Button>
                    </Col>
                </Form.Row>
            </>
        );
    }

    onShippingMethodChange(e) {
        this.setState(
            {
                shipping_method: e.target.value,
                shipping_has_been_calculated: false
            }
        );
    }

    renderShippingMethods() {
        var total_quantity = 0;
        for(let i = 0; i < this.state.order_tech.length; i++) {
            total_quantity += this.state.order_tech[i].quantity;
        }
        if(this.state.dropship) {
            if(this.state.dropship_recepient_country === 'United States') {
                if(total_quantity > 3) {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'PriorityMail'} selected={'selected'}>Priority Mail</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'USLetter'}>US Letter(Untracked, Uninsured, and Non-Refundable)</option>
                                    <option value={'PriorityMail'} selected={'selected'}>Priority Mail(Recommended)</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                }
            } else {
                if(total_quantity > 3) {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'InternationalPackage'} selected={'selected'}>International Package</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'InternationalLetter'}>International Letter</option>
                                    <option value={'InternationalPackage'} selected={'selected'}>International Package</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                }
            }
        } else {
            if(this.state.shipping_country === 'United States') {
                if(total_quantity > 3) {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'PriorityMail'} selected={'selected'}>Priority Mail</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'USLetter'}>US Letter(Untracked, Uninsured, and Non-Refundable)</option>
                                    <option value={'PriorityMail'} selected={'selected'}>Priority Mail(Recommended)</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                }
            } else {
                if(total_quantity > 3) {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'InternationalPackage'} selected={'selected'}>International Package</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Form.Row style={{marginTop: 10}}>
                                Select Shipping Method
                                <Form.Control inline as="select" custom onChange={(e) => {this.onShippingMethodChange(e);}}>
                                    <option value={'InternationalLetter'}>InternationalLetter</option>
                                    <option value={'InternationalPackage'} selected={'selected'}>International Package</option>
                                </Form.Control>
                            </Form.Row>
                        </>
                    );
                }
            }
        }
    }
    renderOrderForm() {
        if(this.state.shipping_is_billing) {
            return (
                [
                    this.renderPersonalInfo(),
                    this.renderCreditCardInformation(),
                    //this.renderDropshipToggle(),
                    this.renderBillingAddressInfo(),
                    this.renderDropshipAddressInfo(),
                    this.renderDropshipRecepientInfo(),
                    this.renderShippingMethods(),
                    this.renderCouponCode(),
                ]
            );
        } else {
            return (
                [
                    this.renderPersonalInfo(),
                    this.renderCreditCardInformation(),
                    //this.renderDropshipToggle(),
                    this.renderBillingAddressInfo(),
                    this.renderShippingAddressInfo(),
                    this.renderDropshipAddressInfo(),
                    this.renderDropshipRecepientInfo(),
                    this.renderShippingMethods(),
                    this.renderCouponCode(),
                ]
            );
        }
    }

    onPlaceOrder() {
        this.setState(
            {
                submitted: true
            }, () => {
                var all_correct = true;
                if(this.state.payment_method === 'Credit Card') {
                    if(!allText(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name Must Only Contain Letters');
                    } else if(isBlank(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name is a Required Field');
                    } else if(!allText(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name Must Only Contain Letters');
                    } else if (isBlank(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name is a Required Field');
                    } else if(!isRFC822ValidEmail(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is not Valid');
                    } else if(isBlank(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is a Required Field');
                    } else if(!allNumbers(this.state.card_number)) {
                        all_correct = false;
                        this.updateModal('Card Number Must Only Contain Numbers');
                    } else if (isBlank(this.state.card_number)) {
                        all_correct = false;
                        this.updateModal('Card Number is a Required Field');
                    } else if(!allNumbers(this.state.card_expiration_month)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Month Must Only Contain Numbers');
                    } else if(isBlank(this.state.card_expiration_month)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Month is a Required Field');
                    } else if(!allNumbers(this.state.card_expiration_year)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Year Must Only Contain Numbers');
                    } else if(isBlank(this.state.card_expiration_year)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Year is a Required Field');
                    } else if(!allNumbers(this.state.card_cvv)) {
                        all_correct = false;
                        this.updateModal('Card CVV Must Only Contain Numbers');
                    } else if(isBlank(this.state.card_cvv)) {
                        all_correct = false;
                        this.updateModal('Card CVV is a Required Field');
                    }
                } else {
                    if(!allText(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name Must Only Contain Letters');
                    } else if(isBlank(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name is a Required Field');
                    } else if(!allText(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name Must Only Contain Letters');
                    } else if (isBlank(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name is a Required Field');
                    } else if(!isRFC822ValidEmail(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is not Valid');
                    } else if(isBlank(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is a Required Field');
                    }
                }
                if(all_correct) {
                    if(this.state.dropship) {
                        if(isBlank(this.state.dropship_address_1)) {
                            all_correct = false;
                            this.updateModal('Dropship Business Address is a Required Field');
                        } else if(!allText(this.state.dropship_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Business State Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Business State is a Required Field');
                        } else if(!allText(this.state.dropship_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Business City Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Business City is a Required Field');
                        } else if(isBlank(this.state.dropship_zip)) {
                            all_correct = false;
                            this.updateModal('Dropship Business Zip Code is a Required Field');
                        } else if(isBlank(this.state.dropship_recepient_address_1)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient Address is a Required Field');
                        } else if(!allText(this.state.dropship_recepient_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient State Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_recepient_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient State is a Required Field');
                        } else if(!allText(this.state.dropship_recepient_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient City Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_recepient_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient City is a Required Field');
                        } else if(isBlank(this.state.dropship_recepient_zip)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient Zip Code is a Required Field');
                        }
                    }
                    if(isBlank(this.state.billing_address_1)) {
                        all_correct = false;
                        this.updateModal('Billing Address is a Required Field');
                    } else if(!allText(this.state.billing_state)) {
                        all_correct = false;
                        this.updateModal('Billing State Must Only Contain Letters');
                    } else if(isBlank(this.state.billing_state)) {
                        all_correct = false;
                        this.updateModal('Billing State is a Required Field');
                    } else if(!allText(this.state.billing_city)) {
                        all_correct = false;
                        this.updateModal('Billing City Must Only Contain Letters');
                    } else if(isBlank(this.state.billing_city)) {
                        all_correct = false;
                        this.updateModal('Billing City is a Required Field');
                    } else if(isBlank(this.state.billing_zip)) {
                        all_correct = false;
                        this.updateModal('Billing Zip Code is a Required Field');
                    } else if(isBlank(this.state.shipping_address_1)) {
                        all_correct = false;
                        this.updateModal('Shipping Address is a Required Field');
                    } else if(!allText(this.state.shipping_state)) {
                        all_correct = false;
                        this.updateModal('Shipping State Must Only Contain Letters');
                    } else if(isBlank(this.state.shipping_state)) {
                        all_correct = false;
                        this.updateModal('Shipping State is a Required Field');
                    } else if(!allText(this.state.shipping_city)) {
                        all_correct = false;
                        this.updateModal('Shipping City Must Only Contain Letters');
                    } else if(isBlank(this.state.shipping_city)) {
                        all_correct = false;
                        this.updateModal('Shipping City is a Required Field');
                    } else if(isBlank(this.state.shipping_zip)) {
                        all_correct = false;
                        this.updateModal('Shipping Zip Code is a Required Field');
                    }
                }

                var order_object = {
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    email_address: this.state.email_address,
                    card_number: this.state.card_number,
                    card_expiration_month: this.state.card_expiration_month,
                    card_expiration_year: this.state.card_expiration_year,
                    card_cvv: this.state.card_cvv,
                    billing_address_1: this.state.billing_address_1,
                    billing_address_2: this.state.billing_address_2,
                    billing_state: this.state.billing_state,
                    billing_city: this.state.billing_city,
                    billing_zip: this.state.billing_zip,
                    billing_country: this.state.billing_country,
                    shipping_address_1: this.state.shipping_address_1,
                    shipping_address_2: this.state.shipping_address_2,
                    shipping_state: this.state.shipping_state,
                    shipping_city: this.state.shipping_city,
                    shipping_zip: this.state.shipping_zip,
                    shipping_country: this.state.shipping_country,
                    shipping_is_billing: this.state.shipping_is_billing,
                    subtotal: this.state.subtotal,
                    shipping: this.state.shipping,
                    tax: this.state.tax,
                    total: this.state.total,
                    order_tech: this.props.order,
                    order_id: this.props.order_id,
                    shipping_rate_id:  this.state.shipping_rate_id,
                    coupon_code: this.state.coupon_code,
                    notes: this.state.notes,
                    dropship: this.state.dropship,
                    dropship_address_1: this.state.dropship_address_1,
                    dropship_address_2: this.state.dropship_address_2,
                    dropship_state: this.state.dropship_state,
                    dropship_city: this.state.dropship_city,
                    dropship_zipcode: this.state.dropship_zipcode,
                    dropship_country: this.state.dropship_country,
                    dropship_business_name: this.state.dropship_business_name,
                    dropship_recepient_first_name: this.state.dropship_recepient_first_name,
                    dropship_recepient_last_name: this.state.dropship_recepient_last_name,
                    dropship_recepient_address_1: this.state.dropship_recepient_address_1,
                    dropship_recepient_address_2: this.state.dropship_recepient_address_2,
                    dropship_recepient_city: this.state.dropship_recepient_city,
                    dropship_recepient_state: this.state.dropship_recepient_state,
                    dropship_recepient_zipcode: this.state.dropship_recepient_zipcode,
                    dropship_recepient_country: this.state.dropship_recepient_country
                };
                if(all_correct) {
                    axios.post('https://api.hybridtextiles.org/api', {flag: 'order', order_data: order_object}
                    ).then((response) => {
                        if(response.data.success) {
                            this.props.prepareOrderConfirmation(response.data.data.email_address, response.data.data.order_id, 'orderconfirmation');
                        } else {
                            this.setState(
                                {
                                    shipping_disabled: false,
                                    checkout_disabled: false,
                                    submitted: false,
                                }
                            );
                            this.updateModal(response.data.error);
                        }
                    });
                }
            }
        );
    }

    createOrder(data, actions) {
        return actions.order
            .create({
                purchase_units: [
                    {
                        amount: {
                            value: this.state.total,
                        },
                    },
                ],
            })
            .then((orderID) => {
                return orderID;
            });
    }

    onPaypalSuccess(useless_data, more_useless_data) {
        this.setState(
            {
                submitted: true
            }, () => {
                var all_correct = true;
                if(this.state.payment_method === 'Credit Card') {
                    if(!allText(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name Must Only Contain Letters');
                    } else if(isBlank(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name is a Required Field');
                    } else if(!allText(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name Must Only Contain Letters');
                    } else if (isBlank(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name is a Required Field');
                    } else if(!isRFC822ValidEmail(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is not Valid');
                    } else if(isBlank(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is a Required Field');
                    } else if(!allNumbers(this.state.card_number)) {
                        all_correct = false;
                        this.updateModal('Card Number Must Only Contain Numbers');
                    } else if (isBlank(this.state.card_number)) {
                        all_correct = false;
                        this.updateModal('Card Number is a Required Field');
                    } else if(!allNumbers(this.state.card_expiration_month)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Month Must Only Contain Numbers');
                    } else if(isBlank(this.state.card_expiration_month)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Month is a Required Field');
                    } else if(!allNumbers(this.state.card_expiration_year)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Year Must Only Contain Numbers');
                    } else if(isBlank(this.state.card_expiration_year)) {
                        all_correct = false;
                        this.updateModal('Card Expiration Year is a Required Field');
                    } else if(!allNumbers(this.state.card_cvv)) {
                        all_correct = false;
                        this.updateModal('Card CVV Must Only Contain Numbers');
                    } else if(isBlank(this.state.card_cvv)) {
                        all_correct = false;
                        this.updateModal('Card CVV is a Required Field');
                    }
                } else {
                    if(!allText(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name Must Only Contain Letters');
                    } else if(isBlank(this.state.first_name)) {
                        all_correct = false;
                        this.updateModal('First Name is a Required Field');
                    } else if(!allText(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name Must Only Contain Letters');
                    } else if (isBlank(this.state.last_name)) {
                        all_correct = false;
                        this.updateModal('Last Name is a Required Field');
                    } else if(!isRFC822ValidEmail(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is not Valid');
                    } else if(isBlank(this.state.email_address)) {
                        all_correct = false;
                        this.updateModal('Email Address is a Required Field');
                    }
                }
                if(all_correct) {
                    if(this.state.dropship) {
                        if(isBlank(this.state.dropship_address_1)) {
                            all_correct = false;
                            this.updateModal('Dropship Business Address is a Required Field');
                        } else if(!allText(this.state.dropship_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Business State Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Business State is a Required Field');
                        } else if(!allText(this.state.dropship_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Business City Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Business City is a Required Field');
                        } else if(isBlank(this.state.dropship_zip)) {
                            all_correct = false;
                            this.updateModal('Dropship Business Zip Code is a Required Field');
                        } else if(isBlank(this.state.dropship_recepient_address_1)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient Address is a Required Field');
                        } else if(!allText(this.state.dropship_recepient_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient State Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_recepient_state)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient State is a Required Field');
                        } else if(!allText(this.state.dropship_recepient_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient City Must Only Contain Letters');
                        } else if(isBlank(this.state.dropship_recepient_city)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient City is a Required Field');
                        } else if(isBlank(this.state.dropship_recepient_zip)) {
                            all_correct = false;
                            this.updateModal('Dropship Recepient Zip Code is a Required Field');
                        }
                    } else {
                        if(isBlank(this.state.billing_address_1)) {
                            all_correct = false;
                            this.updateModal('Billing Address is a Required Field');
                        } else if(!allText(this.state.billing_state)) {
                            all_correct = false;
                            this.updateModal('Billing State Must Only Contain Letters');
                        } else if(isBlank(this.state.billing_state)) {
                            all_correct = false;
                            this.updateModal('Billing State is a Required Field');
                        } else if(!allText(this.state.billing_city)) {
                            all_correct = false;
                            this.updateModal('Billing City Must Only Contain Letters');
                        } else if(isBlank(this.state.billing_city)) {
                            all_correct = false;
                            this.updateModal('Billing City is a Required Field');
                        } else if(isBlank(this.state.billing_zip)) {
                            all_correct = false;
                            this.updateModal('Billing Zip Code is a Required Field');
                        } else if(isBlank(this.state.shipping_address_1)) {
                            all_correct = false;
                            this.updateModal('Shipping Address is a Required Field');
                        } else if(!allText(this.state.shipping_state)) {
                            all_correct = false;
                            this.updateModal('Shipping State Must Only Contain Letters');
                        } else if(isBlank(this.state.shipping_state)) {
                            all_correct = false;
                            this.updateModal('Shipping State is a Required Field');
                        } else if(!allText(this.state.shipping_city)) {
                            all_correct = false;
                            this.updateModal('Shipping City Must Only Contain Letters');
                        } else if(isBlank(this.state.shipping_city)) {
                            all_correct = false;
                            this.updateModal('Shipping City is a Required Field');
                        } else if(isBlank(this.state.shipping_zip)) {
                            all_correct = false;
                            this.updateModal('Shipping Zip Code is a Required Field');
                        }
                    }
                }

                var order_object = {
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    email_address: this.state.email_address,
                    billing_address_1: this.state.billing_address_1,
                    billing_address_2: this.state.billing_address_2,
                    billing_state: this.state.billing_state,
                    billing_city: this.state.billing_city,
                    billing_zip: this.state.billing_zip,
                    billing_country: this.state.billing_country,
                    shipping_address_1: this.state.shipping_address_1,
                    shipping_address_2: this.state.shipping_address_2,
                    shipping_state: this.state.shipping_state,
                    shipping_city: this.state.shipping_city,
                    shipping_zip: this.state.shipping_zip,
                    shipping_country: this.state.shipping_country,
                    shipping_is_billing: this.state.shipping_is_billing,
                    subtotal: this.state.subtotal,
                    shipping: this.state.shipping,
                    tax: this.state.tax,
                    total: this.state.total,
                    order_tech: this.props.order,
                    order_id: this.props.order_id,
                    shipping_rate_id:  this.state.shipping_rate_id,
                    coupon_code: this.state.coupon_code,
                    notes: this.state.notes,
                    dropship: this.state.dropship,
                    dropship_address_1: this.state.dropship_address_1,
                    dropship_address_2: this.state.dropship_address_2,
                    dropship_state: this.state.dropship_state,
                    dropship_city: this.state.dropship_city,
                    dropship_zipcode: this.state.dropship_zipcode,
                    dropship_country: this.state.dropship_country,
                    dropship_business_name: this.state.dropship_business_name,
                    dropship_recepient_first_name: this.state.dropship_recepient_first_name,
                    dropship_recepient_last_name: this.state.dropship_recepient_last_name,
                    dropship_recepient_address_1: this.state.dropship_recepient_address_1,
                    dropship_recepient_address_2: this.state.dropship_recepient_address_2,
                    dropship_recepient_city: this.state.dropship_recepient_city,
                    dropship_recepient_state: this.state.dropship_recepient_state,
                    dropship_recepient_zipcode: this.state.dropship_recepient_zipcode,
                    dropship_recepient_country: this.state.dropship_recepient_country
                };
                if(all_correct) {
                    axios.post('https://api.hybridtextiles.org/api', {flag: 'paypal_order', order_data: order_object}
                    ).then((response) => {
                        if(response.data.success) {
                            this.props.prepareOrderConfirmation(response.data.data.email_address, response.data.data.order_id, 'orderconfirmation');
                        } else {
                            this.setState(
                                {
                                    shipping_disabled: false,
                                    checkout_disabled: false,
                                    submitted: false,
                                }
                            );
                            this.updateModal(response.data.error);
                        }
                    });
                }
            }
        );
    }

    onCalculateShipping() {
        var all_correct = true;
        if(this.state.payment_method === 'Credit Card') {
            if(!allText(this.state.first_name)) {
                all_correct = false;
                this.updateModal('First Name Must Only Contain Letters');
            } else if(isBlank(this.state.first_name)) {
                all_correct = false;
                this.updateModal('First Name is a Required Field');
            } else if(!allText(this.state.last_name)) {
                all_correct = false;
                this.updateModal('Last Name Must Only Contain Letters');
            } else if (isBlank(this.state.last_name)) {
                all_correct = false;
                this.updateModal('Last Name is a Required Field');
            } else if(!isRFC822ValidEmail(this.state.email_address)) {
                all_correct = false;
                this.updateModal('Email Address is not Valid');
            } else if(isBlank(this.state.email_address)) {
                all_correct = false;
                this.updateModal('Email Address is a Required Field');
            } else if(!allNumbers(this.state.card_number)) {
                all_correct = false;
                this.updateModal('Card Number Must Only Contain Numbers');
            } else if (isBlank(this.state.card_number)) {
                all_correct = false;
                this.updateModal('Card Number is a Required Field');
            } else if(!allNumbers(this.state.card_expiration_month)) {
                all_correct = false;
                this.updateModal('Card Expiration Month Must Only Contain Numbers');
            } else if(isBlank(this.state.card_expiration_month)) {
                all_correct = false;
                this.updateModal('Card Expiration Month is a Required Field');
            } else if(!allNumbers(this.state.card_expiration_year)) {
                all_correct = false;
                this.updateModal('Card Expiration Year Must Only Contain Numbers');
            } else if(isBlank(this.state.card_expiration_year)) {
                all_correct = false;
                this.updateModal('Card Expiration Year is a Required Field');
            } else if(!allNumbers(this.state.card_cvv)) {
                all_correct = false;
                this.updateModal('Card CVV Must Only Contain Numbers');
            } else if(isBlank(this.state.card_cvv)) {
                all_correct = false;
                this.updateModal('Card CVV is a Required Field');
            }
        } else {
            if(!allText(this.state.first_name)) {
                all_correct = false;
                this.updateModal('First Name Must Only Contain Letters');
            } else if(isBlank(this.state.first_name)) {
                all_correct = false;
                this.updateModal('First Name is a Required Field');
            } else if(!allText(this.state.last_name)) {
                all_correct = false;
                this.updateModal('Last Name Must Only Contain Letters');
            } else if (isBlank(this.state.last_name)) {
                all_correct = false;
                this.updateModal('Last Name is a Required Field');
            } else if(!isRFC822ValidEmail(this.state.email_address)) {
                all_correct = false;
                this.updateModal('Email Address is not Valid');
            } else if(isBlank(this.state.email_address)) {
                all_correct = false;
                this.updateModal('Email Address is a Required Field');
            }
        }
        if(all_correct) {
            if(this.state.dropship) {
                if(isBlank(this.state.dropship_address_1)) {
                    all_correct = false;
                    this.updateModal('Dropship Business Address is a Required Field');
                } else if(!allText(this.state.dropship_state)) {
                    all_correct = false;
                    this.updateModal('Dropship Business State Must Only Contain Letters');
                } else if(isBlank(this.state.dropship_state)) {
                    all_correct = false;
                    this.updateModal('Dropship Business State is a Required Field');
                } else if(!allText(this.state.dropship_city)) {
                    all_correct = false;
                    this.updateModal('Dropship Business City Must Only Contain Letters');
                } else if(isBlank(this.state.dropship_city)) {
                    all_correct = false;
                    this.updateModal('Dropship Business City is a Required Field');
                } else if(isBlank(this.state.dropship_zip)) {
                    all_correct = false;
                    this.updateModal('Dropship Business Zip Code is a Required Field');
                } else if(isBlank(this.state.dropship_recepient_address_1)) {
                    all_correct = false;
                    this.updateModal('Dropship Recepient Address is a Required Field');
                } else if(!allText(this.state.dropship_recepient_state)) {
                    all_correct = false;
                    this.updateModal('Dropship Recepient State Must Only Contain Letters');
                } else if(isBlank(this.state.dropship_recepient_state)) {
                    all_correct = false;
                    this.updateModal('Dropship Recepient State is a Required Field');
                } else if(!allText(this.state.dropship_recepient_city)) {
                    all_correct = false;
                    this.updateModal('Dropship Recepient City Must Only Contain Letters');
                } else if(isBlank(this.state.dropship_recepient_city)) {
                    all_correct = false;
                    this.updateModal('Dropship Recepient City is a Required Field');
                } else if(isBlank(this.state.dropship_recepient_zip)) {
                    all_correct = false;
                    this.updateModal('Dropship Recepient Zip Code is a Required Field');
                }
            } else {
                if(isBlank(this.state.billing_address_1)) {
                    all_correct = false;
                    this.updateModal('Billing Address is a Required Field');
                } else if(!allText(this.state.billing_state)) {
                    all_correct = false;
                    this.updateModal('Billing State Must Only Contain Letters');
                } else if(isBlank(this.state.billing_state)) {
                    all_correct = false;
                    this.updateModal('Billing State is a Required Field');
                } else if(!allText(this.state.billing_city)) {
                    all_correct = false;
                    this.updateModal('Billing City Must Only Contain Letters');
                } else if(isBlank(this.state.billing_city)) {
                    all_correct = false;
                    this.updateModal('Billing City is a Required Field');
                } else if(isBlank(this.state.billing_zip)) {
                    all_correct = false;
                    this.updateModal('Billing Zip Code is a Required Field');
                } else if(isBlank(this.state.shipping_address_1)) {
                    all_correct = false;
                    this.updateModal('Shipping Address is a Required Field');
                } else if(!allText(this.state.shipping_state)) {
                    all_correct = false;
                    this.updateModal('Shipping State Must Only Contain Letters');
                } else if(isBlank(this.state.shipping_state)) {
                    all_correct = false;
                    this.updateModal('Shipping State is a Required Field');
                } else if(!allText(this.state.shipping_city)) {
                    all_correct = false;
                    this.updateModal('Shipping City Must Only Contain Letters');
                } else if(isBlank(this.state.shipping_city)) {
                    all_correct = false;
                    this.updateModal('Shipping City is a Required Field');
                } else if(isBlank(this.state.shipping_zip)) {
                    all_correct = false;
                    this.updateModal('Shipping Zip Code is a Required Field');
                }
            }
        }

        var order_object = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email_address: this.state.email_address,
            card_number: this.state.card_number,
            card_expiration_month: this.state.card_expiration_month,
            card_expiration_year: this.state.card_expiration_year,
            card_cvv: this.state.card_cvv,
            billing_address_1: this.state.billing_address_1,
            billing_address_2: this.state.billing_address_2,
            billing_state: this.state.billing_state,
            billing_city: this.state.billing_city,
            billing_zip: this.state.billing_zip,
            billing_country: this.state.billing_country,
            shipping_address_1: this.state.shipping_address_1,
            shipping_address_2: this.state.shipping_address_2,
            shipping_state: this.state.shipping_state,
            shipping_city: this.state.shipping_city,
            shipping_zip: this.state.shipping_zip,
            shipping_country: this.state.shipping_country,
            shipping_is_billing: this.state.shipping_is_billing,
            subtotal: this.state.subtotal,
            shipping: this.state.shipping,
            tax: this.state.tax,
            total: this.state.total,
            order_tech: this.props.order,
            order_id: this.props.order_id,
            dropship: this.state.dropship,
            dropship_address_1: this.state.dropship_address_1,
            dropship_address_2: this.state.dropship_address_2,
            dropship_state: this.state.dropship_state,
            dropship_city: this.state.dropship_city,
            dropship_zipcode: this.state.dropship_zipcode,
            dropship_country: this.state.dropship_country,
            dropship_business_name: this.state.dropship_business_name,
            dropship_recepient_first_name: this.state.dropship_recepient_first_name,
            dropship_recepient_last_name: this.state.dropship_recepient_last_name,
            dropship_recepient_address_1: this.state.dropship_recepient_address_1,
            dropship_recepient_address_2: this.state.dropship_recepient_address_2,
            dropship_recepient_state: this.state.dropship_recepient_state,
            dropship_recepient_city: this.state.dropship_recepient_city,
            dropship_recepient_zipcode: this.state.dropship_recepient_zipcode,
            dropship_recepient_country: this.state.dropship_recepient_country,
            shipping_method: this.state.shipping_method
        };
        if(all_correct) {
            this.setState(
                {
                    waiting_for_shipping_calculation: true
                }, () => {
                    axios.post('https://api.hybridtextiles.org/api', {flag: 'calculate_shipping', order_data: order_object}
                    ).then((response) => {
                        if(response.data.success) {
                            var shipping = parseFloat(response.data.data.shipping_cost);
                            this.setState(
                                {
                                    shipping_rate_id: response.data.data.rate_id,
                                    shipping: shipping.toFixed(2),
                                    estimated_delivery_days: response.data.data.estimated_delivery,
                                    shipping_has_been_calculated: true,
                                    waiting_for_shipping_calculation: false,
                                    tax: ((parseFloat(this.state.coupon_subtotal) + shipping)*TAX_RATE).toFixed(2),
                                    total: ((parseFloat(this.state.coupon_subtotal) + shipping) + ((parseFloat(this.state.coupon_subtotal) + shipping)*TAX_RATE)).toFixed(2)
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    waiting_for_shipping_calculation: false,
                                    shipping_disabled: false
                                }
                            );
                            this.updateModal(response.data.error);
                        }
                    });
                }
            );
        }
    }

    renderItemizedReceipt() {
        var items = [];
        for(let i = 0; i < this.props.order.length; i++) {
            if(i % 2 == 0) {
                items.push(
                    <Row style={receiptRowLight} >
                        <Col>
                            {this.props.order[i].filename}
                        </Col>
                        <Col>
                            {this.props.order[i].quantity}
                        </Col>
                        <Col>
                            {this.props.order[i].num_colors}
                        </Col>
                        <Col>
                            {parseFloat(this.props.order[i].height_inches).toFixed(2)}x{parseFloat(this.props.order[i].width_inches).toFixed(2)} in^2
                        </Col>
                        <Col>
                            $ {this.props.order[i].price.toFixed(2)}
                        </Col>
                    </Row>
                );
            } else {
                items.push(
                    <Row style={receiptRowDark} >
                        <Col>
                            {this.props.order[i].filename}
                        </Col>
                        <Col>
                            {this.props.order[i].quantity}
                        </Col>
                        <Col>
                            {this.props.order[i].num_colors}
                        </Col>
                        <Col>
                            {parseFloat(this.props.order[i].height_inches).toFixed(2)}x{parseFloat(this.props.order[i].width_inches).toFixed(2)} in^2
                        </Col>
                        <Col>
                            $ {this.props.order[i].price.toFixed(2)}
                        </Col>
                    </Row>
                );
            }
        }
        if(this.props.order.length % 2 == 0) {
            items.push(
                <Row style={receiptRowLight}>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                        $ {this.state.subtotal}
                    </Col>
                </Row>
            );
        } else {
            items.push(
                <Row style={receiptRowDark}>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                        $ {this.state.subtotal}
                    </Col>
                </Row>
            );
        }
        return items;
    }

    renderReceipt() {
        return (
            <>
                <Row>
                    <Col>
                        <b>
                            Bill of Goods
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Filename
                    </Col>
                    <Col>
                        Quantity
                    </Col>
                    <Col>
                        # of Colors
                    </Col>
                    <Col>
                        Dimensions
                    </Col>
                    <Col>
                        Total
                    </Col>
                </Row>
                {this.renderItemizedReceipt()}
            </>
        );
    }

    renderFinalTotal() {
        return (
            <>
                <Row>
                    <Col sm={1}>
                        Subtotal:
                    </Col>
                    <Col sm={2}>
                        $ {this.state.coupon_subtotal}
                    </Col>
                </Row>
                <Row>
                    <Col sm={1}>
                        Shipping:
                    </Col>
                    <Col sm={3}>
                        $ {this.state.shipping} (USPS Priority Mail)
                    </Col>
                </Row>
                <Row>
                    <Col sm={1}>
                        Tax:
                    </Col>
                    <Col sm={2}>
                        $ {this.state.tax}
                    </Col>
                </Row>
                <Row>
                    <Col sm={1}>
                        Total:
                    </Col>
                    <Col sm={2}>
                        $ {this.state.total}
                    </Col>
                </Row>
            </>
        );
    }

    setModalShow(status) {
        this.setState(
            {
                modal_show: status
            }
        );
    }

    setTOSModalShow(status) {
        this.setState(
            {
                tos_modal_show: status
            }
        );
    }

    updateModal(error_message) {
        this.setState(
            {
                modal_show: true,
                modal_body: error_message
            }
        );
    }

    onNotesChange(e) {
        this.setState(
            {
                notes: e.target.value
            }
        );
    }

    render() {
        var checkout_variant = '';
        var checkout_disabled = true;
        var shipping_variant = '';
        var shipping_disabled = true;
        var coupon_variant = 'info';
        if(!this.state.tos_accepted) {
            checkout_disabled = true;
        } else {
            checkout_disabled = false;
        }
        if(this.state.shipping_has_been_calculated) {
            checkout_variant = 'primary';
            checkout_disabled = false;
        } else {
            checkout_variant = 'secondary';
            checkout_disabled = true;
        }
        if(this.state.waiting_for_shipping_calculation) {
            shipping_variant = 'secondary';
            shipping_disabled = true;
        } else {
            shipping_variant = 'success';
            shipping_disabled = false;
        }
        if(this.state.submitted) {
            shipping_disabled = true;
            checkout_disabled = true;
        }
        if(this.state.payment_method === 'Credit Card') {
            return(
                <>
                    <br />
                    <br />
                    <Container style={finalTotalContainer}>
                        <Container style={receiptContainer}>
                            {this.renderReceipt()}
                        </Container>
                        <Form className='justify-content-end' style={container}>
                            {this.renderOrderForm()}
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label><b>Notes</b>(max 2000 characters)</Form.Label>
                                <Form.Control as="textarea" rows="3" onChange={(e) => {this.onNotesChange(e);}} maxlength="2000"/>
                            </Form.Group>
                        </Form>
                        {this.renderFinalTotal()}
                        <Row>
                            <Col lg={0}>
                                <Form.Check type="checkbox" onChange={(e) => {this.toggleTOS();}} checked={this.state.tos_accepted} className="float-right"/>
                            </Col>
                            <Col lg={0}>
                                <p className='float-left'>I agree to the <a href='#' onClick={() => {this.setTOSModalShow(true);}} >Terms of Service</a>.</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col lg={3}>
                                <Button size="lg" variant={shipping_variant} disabled={shipping_disabled} onClick={() => {this.onCalculateShipping();}}><b>Calculate Shipping</b></Button>
                            </Col>
                            <Col lg={3}>
                                <Button size="lg" variant={checkout_variant} disabled={checkout_disabled} onClick={() => {this.onPlaceOrder();}} ><b>Place Order</b></Button>
                            </Col>
                        </Row>
                        <ErrorModal
                            show={this.state.modal_show}
                            onHide={() => {this.setModalShow(false);}}
                            modal_header={this.state.modal_header}
                            modal_body={this.state.modal_body}
                        />
                        <TOSModal
                            show={this.state.tos_modal_show}
                            onHide={() => {this.setTOSModalShow(false);}}
                        />
                    </Container>
                </>
            );
        } else {
            if(checkout_disabled) {
                return (
                    <>
                        <br />
                        <br />
                        <Container style={finalTotalContainer}>
                            <Container style={receiptContainer}>
                                {this.renderReceipt()}
                            </Container>
                            <Form className='justify-content-end' style={container}>
                                {this.renderOrderForm()}
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label><b>Notes</b>(max 2000 characters)</Form.Label>
                                    <Form.Control as="textarea" rows="3" onChange={(e) => {this.onNotesChange(e);}} maxlength="2000"/>
                                </Form.Group>
                            </Form>
                            {this.renderFinalTotal()}
                            <Row>
                                <Col lg={0}>
                                    <Form.Check type="checkbox" onChange={(e) => {this.toggleTOS();}} checked={this.state.tos_accepted} className="float-right"/>
                                </Col>
                                <Col lg={0}>
                                    <p className="float-left">I agree to the <a href='#' onClick={() => {this.setTOSModalShow(true);}} >Terms of Service</a>.</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col lg={3}>
                                    <Button size="lg" variant={shipping_variant} disabled={shipping_disabled} onClick={() => {this.onCalculateShipping();}}><b>Calculate Shipping</b></Button>
                                </Col>
                                <Col lg={3}>
                                    <Button size="lg" variant={checkout_variant} disabled={true}><b>Place Order</b></Button>
                                </Col>
                            </Row>
                            <ErrorModal
                                show={this.state.modal_show}
                                onHide={() => {this.setModalShow(false);}}
                                modal_header={this.state.modal_header}
                                modal_body={this.state.modal_body}
                            />
                            <TOSModal
                                show={this.state.tos_modal_show}
                                onHide={() => {this.setTOSModalShow(false);}}
                            />
                        </Container>
                    </>
                );
            } else {
                return (
                    <>
                        <br />
                        <br />
                        <Container style={finalTotalContainer}>
                            <Container style={receiptContainer}>
                                {this.renderReceipt()}
                            </Container>
                            <Form className='justify-content-end' style={container}>
                                {this.renderOrderForm()}
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label><b>Notes</b>(max 2000 characters)</Form.Label>
                                    <Form.Control as="textarea" rows="3" onChange={(e) => {this.onNotesChange(e);}} maxlength="2000"/>
                                </Form.Group>
                            </Form>
                            {this.renderFinalTotal()}
                            <Row>
                                <Col lg={0}>
                                    <Form.Check type="checkbox" onChange={(e) => {this.toggleTOS();}} checked={this.state.tos_accepted} className="float-right"/>
                                </Col>
                                <Col lg={0}>
                                    <p className="float-left">I agree to the <a href='#' onClick={() => {this.setTOSModalShow(true);}} >Terms of Service</a>.</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col lg={3}>
                                    <Button size="lg" variant={shipping_variant} disabled={shipping_disabled} onClick={() => {this.onCalculateShipping();}}><b>Calculate Shipping</b></Button>
                                </Col>
                                <Col lg={3}>
                                    <PayPalButton
                                        amount={this.state.total}
                                        onSuccess={(details, data) => {this.onPaypalSuccess(details, data);}}
                                        onError={(error) => {this.updateModal('Error Charging Account. Please Try Again');}}
                                        onCancel={(data) => {}}
                                        options={{
                                            clientId: 'AfRPaVA_9BqTX1U_DQ2O0j7i2DqixR41-6YtvnqWG6kNCCQVY3prwhpzbGuPKruP5Ku46PaEYp18sIOG'
                                        }}
                                        style={{
                                            layout: 'horizontal',
                                            tagline: false,
                                            shape: 'rect',
                                            color: 'gold'
                                        }}
                                    />
                                </Col>

                            </Row>
                            <ErrorModal
                                show={this.state.modal_show}
                                onHide={() => {this.setModalShow(false);}}
                                modal_header={this.state.modal_header}
                                modal_body={this.state.modal_body}
                            />
                            <TOSModal
                                show={this.state.tos_modal_show}
                                onHide={() => {this.setTOSModalShow(false);}}
                            />
                        </Container>
                    </>
                );
            }
        }
    }
}
