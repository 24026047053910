export const swatch = {
    padding: 0
};

export const arrow = {
    height: 25,
    width: 25
};

export const wire = {
    border: 'solid black 2px',
    marginBottom: 20
};

export const select_backing_box = {
    backgroundColor: 'black',
    color: 'white',
    border: 'solid #5f88b7 2px',
    width: 150,
};

export const select_backing_box_error = {
    backgroundColor: 'black',
    color: 'white',
    border: 'solid #5f88b7 2px',
    width: 150,
    boxShadow: '0px 0px 5px 2px red'
};

export const select_color_box = {
    border: 'solid #5f88b7 2px',
    backgroundColor: 'black',
    color: 'white'
};

export const canvas_box = {
    border: 'solid #5f88b7 2px',
    backgroundColor: 'black',
    color: 'white'
};

export const image_upload_box = {
    border: 'solid #5f88b7 2px',
    marginBottom: 20,
    backgroundColor: 'black',
    color: 'white'
};

export const image_upload_box_error = {
    border: 'solid #5f88b7 2px',
    marginBottom: 20,
    backgroundColor: 'black',
    boxShadow: '0px 0px 5px 2px red',
    color: 'black'
};

export const image_uploader_box = {
    border: 'solid #5f88b7 2px',
    backgroundColor: 'black',
    height: 250,
    color: 'black'
};

export const total_values_box = {
    marginBottom: 20,
    backgroundColor: 'black',
    border: 'solid #5f88b7 3px',
    color: 'white'
};

export const quantity_row = {
    marginBottom: 10,
    backgroundColor: 'black',
    color: 'white'
};

export const num_colors_row = {
    marginBottom: 10,
    backgroundColor: 'black',
    color: 'white'
};

export const adjust_height_col = {
};

export const adjust_height_input = {
    marginLeft: -7,
    width: 50
};

export const adjust_height_container = {
    marginTop: 80,
    backgroundColor: 'black',
    color: 'white'
};

export const stage_style = {
    marginBottom: 10,
    color: 'white'
};

export const adjust_width_col = {
    backgroundColor: 'black',
    width: 200,
    color: 'white'
};

export const adjust_width_input = {
    width: 50,
    marginLeft: 13
};

export const left_arrow = {
    marginRight: 50
};

export const right_arrow = {
    marginLeft: 50
};

export const up_arrow = {
    marginBottom: 50
};

export const down_arrow = {
    marginTop: 50
};

export const checkout_button = {
    
};

export const checkout_button_disabled = {
    
};

export const select_border_box = {
    backgroundColor: 'black',
    color: 'white',
    border: 'solid #5f88b7 2px',
    width: 150
};

export const select_border_box_error = {
    backgroundColor: 'black',
    color: 'white',
    border: 'solid #5f88b7 2px',
    width: 150,
    boxShadow: '0px 0px 5px 2px red'
};

export const border_backing_column = {
    backgroundColor: 'black',
    color: 'white',
    width: 200,
};
