import background_tile from './components/Images/background_tile.png';
export const navbarImage = {
    height: 60
}

export const navbarStyle = {
    color: 'white',
    backgroundColor: 'black',
    backgroundImage: 'none !important',
    background: 'black',
    fixed: 'top',
    border: '1px solid black',
}

export const textLink = {
    color: 'white',
}

export const app = {
    //backgroundImage: `url(${background_tile})`
    backgroundColor: 'black'
};

export const footerRow = {
    backgroundColor: 'black',
    color: 'white'
}

export const NavbarIcon = {
    height: 50,
    width: 50
}
