import React from 'react';
import { useState, setIndex } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



export default class PaymentMethodsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        Twillry accepts payments via credit and debit from all major card companies including Visa, MasterCard, Discover, American Express and their counterparts.
                    </Col>
                </Row>
            </Container>
        );
    }
}
