
export function allText(string) {
    var regex = /^[a-zA-Z ]*$/;
    var result = string.match(regex);
    if(result === null) {
        return false;
    }
    return true;
}

export function allNumbers(string) {
    var regex = /^[0-9]*$/;
    var result = string.match(regex);
    if(result === null) {
        return false;
    }
    return true;
}

export function isBlank(string) {
    return string === '';
}

export function isRFC822ValidEmail(sEmail) {  // http://www.rosskendall.com/blog/web/javascript-function-to-check-an-email-address-conforms-to-rfc822

    var sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
    var sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
    var sAtom = '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
    var sQuotedPair = '\\x5c[\\x00-\\x7f]';
    var sDomainLiteral = '\\x5b(' + sDtext + '|' + sQuotedPair + ')*\\x5d';
    var sQuotedString = '\\x22(' + sQtext + '|' + sQuotedPair + ')*\\x22';
    var sDomain_ref = sAtom;
    var sSubDomain = '(' + sDomain_ref + '|' + sDomainLiteral + ')';
    var sWord = '(' + sAtom + '|' + sQuotedString + ')';
    var sDomain = sSubDomain + '(\\x2e' + sSubDomain + ')*';
    var sLocalPart = sWord + '(\\x2e' + sWord + ')*';
    var sAddrSpec = sLocalPart + '\\x40' + sDomain; // complete RFC822 email address spec
    var sValidEmail = '^' + sAddrSpec + '$'; // as whole string

    var reValidEmail = new RegExp(sValidEmail);

    if (reValidEmail.test(sEmail)) {
        return true;
    }

    return false;
}

export function isNum(string) {
    return /^\d+$/.test(string);
}
