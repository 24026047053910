import React from 'react';
import { useState, setIndex } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import deer1 from '../../Images/deer1.jpg';
import deer2 from '../../Images/deer2.jpg';
import deer1result from '../../Images/deer1result.png';
import deer2result from '../../Images/deer2result.png';


export default class NewsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col style={{backgroundColor: 'white'}}>
                        <b>What kind of images can I upload?</b><br />
                        You may upload any JPG or PNG image at this time<br /><br />
                        <b>Can I upload an image of XYZ?</b><br />
                        As long as the image doesn't violate any laws, we do not have a policy on image/patch content<br /><br />
                        <b>Why doesn't my image look good on the preview screen?</b><br />
                        Unfortunately not all images make good patches. Images with a lot of small detail tend to not look great and are not possible to embroider.<br />
                        We recommend simplyfing your image using editing software such as Gimp and reuploading the image to see the results.<br /><br />
                        <b>Why are some of the details of my image missing?</b><br />
                        Due to the constraints of embroidery, not every detail can be embroidered. There is a limit in what embroidery can accomplish and we try to visualize it as much as we can<br /><br />
                        <b>Why does some of the text not render correctly?</b><br />
                        Unfortunately this comes down to detail. Some fonts are too precise for embroidery to be able to do. We recommend using blocky and well defined fonts.<br /><br />
                        <b>How can I make my image render better?</b><br />
                        Excellent question! The single best thing one can do to make their images and patches look better is to center on the design. Below is an example of cropping the design out of the image to yield better results.<br />
                        Original Image<br />
                        <Image src={deer1} /><Image src={deer1result} /><br />
                        Cropped Image<br />
                        <Image src={deer2} /><Image src={deer2result } /><br />
                        <b>How can I improve the detail of the render?</b><br />
                        Not only can you focus on the design, you can also increase the size of the patch. You can do this with the arrows on the left and bottom of the render.<br /><br />
                    </Col>
                </Row>
            </Container>
        );
    }
}
