import React from 'react';
import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import HomePage from './components/Pages/HomePage/index.js';
import QuotePage from './components/Pages/QuotePage/index.js';
import CheckoutPage from './components/Pages/CheckoutPage/index.js';
import NewsPage from './components/Pages/NewsPage/index.js';
import OrderConfirmationPage from './components/Pages/OrderConfirmationPage/index.js';
import AboutPage from './components/Pages/AboutPage/index.js';
import TermsOfServicePage from './components/Pages/TermsOfServicePage/index.js';
import PaymentMethodsPage from './components/Pages/PaymentMethodsPage/index.js';
import ExchangesAndReturnsPage from './components/Pages/ExchangesAndReturnsPage/index.js';
import ContactPage from './components/Pages/ContactPage/index.js';
import FAQPage from './components/Pages/FAQPage/index.js';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';

import { BrowserRouter, Router, Route, Link, Redirect, Switch } from 'react-router-dom';

import TwillryLogo from './components/Images/twillrylogo.png';
import HybridLogo from './components/Images/hybridlogo.jpg';
import TwitterIcon from './components/Images/twitter-icon.png';
import InstagramIcon from './components/Images/instagramicon.png';
import FacebookIcon from './components/Images/facebook-logo.png';
import XNavbar from './components/Images/X.jpg';
import InstagramNavbar from './components/Images/instagram.jpg';
import FacebookNavbar from './components/Images/facebook.jpg';
import TiktokNavbar from './components/Images/tiktok.jpg';
import GmailNavbar from './components/Images/gmail.jpg';
import { navbarImage, navbarStyle, textLink, app, footerRow, NavbarIcon} from './App.css.js';

function QuoteRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={
                (props) => {
                    switch(rest.webapp_state) {
                        case 'checkout':
                            return(
                                <Redirect
                                    to={{
                                    pathname: '/checkout',
                                    state: { from: props.location }
                                    }}
                                />
                            );
                        default:
                            return (<Component {...rest} />);
                    }
                }
            }
        />
    );
}

function CheckoutRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={
                (props) => {
                    switch(rest.webapp_state) {
                        case 'orderconfirmation':
                            return(
                                <Redirect
                                    to={{
                                    pathname: '/orderconfirmation',
                                    state: { from: props.location }
                                    }}
                                />
                            );
                        case '':
                            return(
                                <Redirect
                                    to={{
                                    pathname: '/',
                                    state: { from: props.location }
                                    }}
                                />
                            );
                        case 'quote':
                            return(
                                <Redirect
                                    to={{
                                    pathname: '/quote',
                                    state: { from: props.location }
                                    }}
                                />
                            );
                        default:
                            return (<Component {...rest} />);
                    }
                }
            }
        />
    );
}

function OrderConfirmationRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={
                (props) => {
                    switch(rest.webapp_state) {
                        case 'checkout':
                            return(
                                <Redirect
                                    to={{
                                    pathname: '/checkout',
                                    state: { from: props.location }
                                    }}
                                />
                            );
                        case '':
                            return(
                                <Redirect
                                    to={{
                                    pathname: '/',
                                    state: { from: props.location }
                                    }}
                                />
                            );
                        case 'quote':
                            return(
                                <Redirect
                                    to={{
                                    pathname: '/quote',
                                    state: { from: props.location }
                                    }}
                                />
                            );
                        default:
                            return (<Component {...rest} />);
                    }
                }
            }
        />
    );
}

function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
          <Component {...rest} />
      }
    />
  );
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
            order_id: '',
            webapp_state: '',
            redirect: false
        };
    }

    getOrder(custom_shapes) {
        var order_array = [];
        for(let i = 0; i < custom_shapes.length; i++) {
            var temp_order = {
                num_colors: custom_shapes[i].num_colors,
                height_inches: custom_shapes[i].height_inches,
                height_pixels: custom_shapes[i].height_pixels,
                width_inches: custom_shapes[i].width_inches,
                width_pixels: custom_shapes[i].width_pixels,
                quantity: custom_shapes[i].quantity,
                price: custom_shapes[i].price,
                filename: custom_shapes[i].filename,
                shapes: custom_shapes[i].shapes,
                border_color: custom_shapes[i].border_color,
                background_color: custom_shapes[i].background_color,
                backing: custom_shapes[i].backing
            };
            order_array.push(temp_order);
        }
        if(order_array.length > 0) {
            this.setState(
                {
                    order: order_array,
                    webapp_state: 'checkout',
                    redirect: true
                }
            );
        }
    }

    setOrderId(order_id) {
        this.setState(
            {
                order_id: order_id
            }
        );
    }

    resetState() {
        this.setState(
            {
                order: [],
                webapp_state: '',
                order_id: ''
            }
        );
    }

    prepareOrderConfirmation(email_address, order_id, state) {
        var temp_order = {
            email_address: email_address,
            order_id: order_id
        };
        this.setState(
            {
                order: temp_order,
                webapp_state: state
            }
        );
    }

    stateChange(state) {
        this.setState(
            {
                webapp_state: state
            }
        );
    }

    render() {
        return (
            <>
            <BrowserRouter>
                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
                    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
                    crossorigin="anonymous"
                />
                <div className="App" style={app}>
                    <Navbar fixed='top' style={navbarStyle}>
                        <Navbar.Brand><Nav.Link href='/' style={textLink} onClick={() => {this.resetState();}} ><Image src={HybridLogo} style={navbarImage} className={navbarImage} /></Nav.Link></Navbar.Brand>
                        <Navbar.Collapse className="justify-content-end">
                            <Nav>
								<Col>
									<Row>
										<Nav.Link href='mailto:hybridtextilesllc@gmail.com' style={textLink} onClick={() => {this.resetState();}} ><Image src={GmailNavbar} style={NavbarIcon} /></Nav.Link>
									</Row>
									<Row>
										<Nav.Link href='/' style={textLink} onClick={() => {this.resetState();}} >Home</Nav.Link>
									</Row>
								</Col>
								<Col>
									<Row>
                                		<Nav.Link href='https://www.tiktok.com/@hybrid.textiles' style={textLink} onClick={() => {this.resetState();}} ><Image src={TiktokNavbar} style={NavbarIcon} /></Nav.Link>
									</Row>
									<Row>
										<Nav.Link href='/quote' style={textLink} onClick={() => {this.resetState();}} >Upload</Nav.Link>
									</Row>
								</Col>
								<Col>
									<Row>
										<Nav.Link href='https://www.instagram.com/hyrbidtextiles/' style={textLink} onClick={() => {this.resetState();}} ><Image src={InstagramNavbar} style={NavbarIcon} /></Nav.Link>
									</Row>
									<Row>
										<Nav.Link href='/about' style={textLink} onClick={() => {this.resetState();}} >Info</Nav.Link>
									</Row>
								</Col>
								<Col>
									<Row>
										<Nav.Link href='https://x.com/Hybridtextiles' style={textLink} onClick={() => {this.resetState();}} ><Image src={XNavbar} style={NavbarIcon} /></Nav.Link>
									</Row>
									<Row>
										<Nav.Link href='/faq' style={textLink} onClick={() => {this.resetState();}} >FAQ</Nav.Link>
									</Row>
								</Col>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <br /><br /><br /><br />
                    <Switch>
                        <PublicRoute exact path='/' component={HomePage} />
                        <QuoteRoute path='/quote' redirect={this.state.redirect} component={QuotePage} getOrder={(o) => {this.getOrder(o);}} webapp_state={this.state.webapp_state} setOrderId={(id) => {this.setOrderId(id);}} order_id={this.state.order_id} />
                        <CheckoutRoute path='/checkout'
                            component={CheckoutPage}
                            order={this.state.order}
                            webapp_state={this.state.webapp_state}
                            order_id={this.state.order_id}
                            prepareOrderConfirmation={(e, o, s) => {this.prepareOrderConfirmation(e, o, s);}}
                        />
                        <PublicRoute path='/news' component={NewsPage} />
                        <OrderConfirmationRoute path='/orderconfirmation'
                            component={OrderConfirmationPage}
                            webapp_state={this.state.webapp_state}
                            stateChange={(s) => {this.stateChange(s);}}
                            order_id={this.state.order.order_id}
                            email_address={this.state.order.email_address}
                        />
                        <PublicRoute path='/about' component={AboutPage} />
                        <PublicRoute path='/contact' component={ContactPage} />
                        <PublicRoute path='/faq' component={FAQPage} />
                    </Switch>
		{/* <Container style={{border: '1px solid black', marginTop: 10}}>
                        <Row style={footerRow} >
                            <Col md={{span: 2, offset:1}}>
                                <Row>
                                    <b>
                                        Twillry, LLC
                                    </b>
                                </Row>
                                <Row>
                                    <Link to='/about' style={footerRow}>About us</Link>
                                </Row>
                                <Row>
                                    <Link to='/termsofservice' style={footerRow}>Terms of Service</Link>
                                </Row>
                                <Row>
                                    <Link to='/exchanges' style={footerRow}>Exchanges and Returns</Link>
                                </Row>
                            </Col>
                            <Col md={{span: 3, offset: 3}}>
                                <a href='https://twitter.com/twillryllc'><Image src={TwitterIcon} style={{height: 24, width: 24}}/></a>
                                <a href='https://instagram.com/twillryllc'><Image src={InstagramIcon} style={{height: 24, width: 24}}/></a>
                                <a href='https://facebook.com/twillryllc'><Image src={FacebookIcon} style={{height: 24, width: 24}}/></a>
                            </Col>
                        </Row>
                    </Container>
		    */}
                </div>
            </BrowserRouter>
            </>
        );
    }
}

export default App;
