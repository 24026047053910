export const container = {
    backgroundColor: 'black',
    color: 'white',
};

export const receiptRowLight = {
    backgroundColor: 'black',
    color: 'white'
}

export const receiptRowDark = {
    backgroundColor: 'black',
    color: 'white'
}

export const receiptContainer = {
    backgroundColor: 'black',
    color: 'white'
}

export const finalTotalContainer = {
    backgroundColor: 'black',
    color: 'white',
    border: 'solid #5f88b7 2px'
}

export const errorShadow = {
    boxShadow: '0px 0px 10px red'
}

export const noShadow = {
}

export const visaMCDiscoverLength = 16;

export const amexLength = 15;

export const amexCVVLength = 4;

export const visaMCDiscoverCVVLength = 3;

export const ccStyle = {
    height: 36,
    width: 72
};
