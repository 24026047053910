import React from 'react';
import { useState, setIndex } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {container_style} from './index.css.js';


export default class ExchangesAndReturnsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container style={container_style} >
                <Row>
                    <Col>
                        Our customers are our lifeblood at Twillry and we strive to make sure each one is delighted with their purchase. But as with everything else, nothing is perfect. If you feel as if you have received a damaged or subpar product please email us at support@twillry.com with a photo of the product in question and we will review and determine if an exchange or refund can be granted. <br /><br />
                    </Col>
                </Row>
            </Container>
        );
    }
}
