import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';


export default class OrderConfirmationPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <br />
                <br />
                <br />
                <Row>
                    <Col>
                        <b style={{color: 'white'}}>
                            Order #{this.props.order_id} Has Been Received!
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{color: 'white'}}>
                            A confirmation email will be sent to {this.props.email_address} for a full receipt as well as status updates.
                        </p>
                    </Col>
                </Row>
            </>
        );
    }
}
