import React from 'react';
import { useState, setIndex } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';

import intro from '../../Images/FirstCarosel.jpg';
import pic2 from '../../Images/sailorneptunetransition.png';
import tripletwillry from '../../Images/tripletwillry.png';
import twillrystraightgroup from '../../Images/SecondCarosel.jpg';
import twillrycluttergroup from '../../Images/twillrycluttergroup.png';
import pic3 from '../../Images/PatchCollection.jpg';
import card1 from '../../Images/card1.png';
import card2 from '../../Images/card2.png';
import card3 from '../../Images/card3.png';
import card4 from '../../Images/card4.png';
import card5 from '../../Images/card5.png';
import card6 from '../../Images/card6.png';


function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={intro}
            alt="First slide"
            />
            <Carousel.Caption>
                <h2>Custom Made Patches</h2>
                <p>Bulk Pricing</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={twillrystraightgroup}
            alt="Second slide"
            />

            <Carousel.Caption>
            <h2>Real-time Patch Preview</h2>
            <p>Interactive, Customizable, AI-Powered</p>
            </Carousel.Caption>
        </Carousel.Item>
        </Carousel>
    );
}

function firstRowCards() {
    return (
        <CardDeck>
            <Card>
                <Card.Img variant="top" src={card1} />
                <Card.Body>
                <Card.Title>Modernization</Card.Title>
                <Card.Text>
                    The Latest in Textile Manufacturing
                </Card.Text>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={card2} />
                <Card.Body>
                <Card.Title>Expediency</Card.Title>
                <Card.Text>
                    Instant Quoting, Instant Access
                </Card.Text>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={card3} />
                <Card.Body>
                <Card.Title>Customization</Card.Title>
                <Card.Text>
                    Customized Product at Bulk Pricing
                </Card.Text>
                </Card.Body>
            </Card>
        </CardDeck>
    );
}

function secondRowCards() {
    return (
        <CardDeck>
            <Card>
                <Card.Img variant="top" src={card4} />
                <Card.Body>
                <Card.Title>Quality</Card.Title>
                <Card.Text>
                    Sourced in America
                </Card.Text>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={card5} />
                <Card.Body>
                <Card.Title>Craftsmanship</Card.Title>
                <Card.Text>
                    Made in America
                </Card.Text>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={card6} />
                <Card.Body>
                <Card.Title>Technology</Card.Title>
                <Card.Text>
                    Leading the Way in Textile Innovation
                </Card.Text>
                </Card.Body>
            </Card>
        </CardDeck>
    );
}


export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <ControlledCarousel />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    </Col>
                </Row>
            </Container>
        );
    }
}
